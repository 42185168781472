import { Injectable } from "@angular/core";
import { AwsRumConfig, AwsRum } from "aws-rum-web";
import { ConfigService } from "src/app/shared/services/config.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

@Injectable({
  providedIn: "root",
})
export class CwRumService {
  private awsRum: AwsRum;

  constructor(private authService: AuthService, private configService: ConfigService) {}

  initialize(): Promise<void> {
    return new Promise((resolve) => {
      try {
        const cwrConfig: AwsRumConfig = {
          sessionSampleRate: 1,
          endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
          telemetries: ["errors", "performance", "http"],
          allowCookies: true,
          enableXRay: false,
          dispatchInterval: 5000,
        };

        const appConfig = this.configService.getConfig();
        this.awsRum = new AwsRum(appConfig.cwrApplicationId, "1.0.0", appConfig.region, cwrConfig);

        this.authService.jwtToken$.subscribe((token) => {
          if (token) {
            this.updateCredentials(token);
          } else {
            this.awsRum.disable();
          }
        });

        resolve();
      } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
        console.error("CwRumService: initialize error", error);
        resolve();
      }
    });
  }

  handleError(error: any): void {
    if (this.awsRum) {
      this.awsRum.recordError(error);
    }
  }

  updateCredentials(token: string): void {
    const appConfig = this.configService.getConfig();
    const region = appConfig.region;
    const userPoolId = appConfig.userPoolId;
    const identityPoolId = appConfig.cwrIdentityPoolId;

    this.awsRum.setAwsCredentials(
      fromCognitoIdentityPool({
        clientConfig: { region },
        identityPoolId: identityPoolId,
        logins: {
          [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: token,
        },
      }),
    );

    this.awsRum.enable();
  }
}

export function CwRumFactory(configService: ConfigService, cwRumService: CwRumService): () => Promise<any> {
  return (): Promise<any> => {
    return cwRumService.initialize();
  };
}
